/* App Common style */

.row.clear-margin {
  margin: 0 !important;
}

nav.navbar {
  padding: 0;
  margin-bottom: 0px;
}

nav .container-fluid {
  margin: 0;
  padding: 0;
}

nav .navbar-brand {
  padding: 0;
}

.navbar-brand img {
  height: 60px;
}

.logged-user {
  margin-right: 20px;
  width: 340px;
  text-align: right;
  font-size: 13px;
}

.logged-user ul.dropdown-menu {
  position: absolute;
  text-align: right;
  right: 0;
  left: auto;
  width: auto;
  padding: 0;
  border-radius: 0;
  font-size: 12px;
}

.logged-user ul.dropdown-menu a {
  padding: 10px;
  width: 100px;
}

div.selected-menu-item {
  height: 44px;
  background-color: #e8e8e8;
  margin-bottom: 10px;
}
.confirmApproved {
  /* height: 52px; */
  /* background-color: #e8e8e8; */
  float: right;
  margin-right: 10px;
}

.menuItem {
  height: 52px;
  background-color: #e8e8e8;
  /* border-radius: 10px; */
  float: right;
  /* margin: 2px; */
  /* display: inline; */
}

/* .menuItem button{
  margin-right: 10px;
  height: 100%;
  background-color: #2f2d2d;
  color: #fff;
  border-radius: 10px;
  text-align: center;

}
.menuItem button:hover{

  background-color: #121111;


} */
/* .menuItem label{
  height: 100%px;
  width: 50%px;
  background-color: #363636;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  padding: 15px;
  margin-right: 17px;
  
} */

/* .d-flex label{
  height: 100%;
  color: #363636;
  text-align: center;
  margin-right: 10px;
  font-size: 20px;
  padding: 15px;
  padding: 12px 10px 3px 10px;
}
.d-flex label span{
  font-weight: 600;
} */

/* .panel2{
  height: 100%;
  color: #363636;
  text-align: center;
  margin-right: 10px; 
} */

/* .panel2 .day{
  font-size: 20px;
  font-weight: 600;
  padding: 4px 10px 3px 10px;
} */
/* .panel2 .day .col-10{
  margin-top: 3px;
}
.panel2 .week{
  font-size: 18px;
  font-weight: 600;
  padding: 9px 10px 3px 10px;
} */

/* .panel2 .viewDashboard{
  margin-top: 7px;
  padding: 3px 10px 3px 10px;
}
.panel2 .confirmButton{
  border: 2px solid #363636 !important;
  background-image: none !important;
  background-color: #3a3a3a !important;
  padding: 6px 10px;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #fff;
  cursor: pointer;
  transition: background-color ease 0.3s;
  font-size: 12px;
}
.panel2 .totalTimeOFPending{

  border: 2px solid #363636 !important;
  background-image: none !important;
  background-color: #3a3a3a !important;
  padding: 8px 10px;
  border-radius: 5px;
  margin-right: 17px;
  color: #fff;
  cursor: default;
  transition: background-color ease 0.3s;
  font-size: 12px;
} */
.leftFirstMenuItem {
  background-color: #c66161;

  display: inline;
}

.menuItemLeft {
  height: 52px;
  background-color: #e8e8e8;
  border-radius: 10px;
  float: left;
  margin: 2px;
  display: inline;
}
.menuItemLeft label {
  height: 100%px;
  width: 50%px;
  background-color: #e8e8e8;
  color: #363636;
  text-align: center;
  margin-right: 10px;
  font-size: 20px;
  padding: 12px 10px 3px 10px;
}
.menuItemLeft label span {
  font-weight: 600;
}
.menuItemLeft button {
  margin-right: 10px;
  height: 55%;
  background-color: #272525;
  border-radius: 50px;
  text-align: center;
  color: #fff;
  padding: 2px 15px 15px 15px;
}
.menuItemLeft button a:hover {
  background-color: #0e0d0d;
  text-decoration: underline;
}
.menuItemLeft button a {
  color: #fff;
  text-decoration: none;
  background-color: #2f2c2c;
}
main.container-fluid {
  margin: 0;
  padding: 0;
}

.rejectApproveButtonSection {
  height: 52px;
  border-radius: 10px;
  float: right;
  margin: 2px;
  display: inline;
}
div.projectList {
  overflow-y: auto;
}

.form-select-sm {
  font-weight: bold;
}
/* Detail view styles---------- */

.detail-block {
  margin: 50px;
  margin-left: 20px;
}

.detail-header {
  margin-bottom: 20px;
}

.detail-header .header-text {
  margin-right: 20px;
  font-size: 18px;
  font-weight: 600;
}

.detail-info-block {
  font-size: 16px;
  color: #888888;
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
  padding-bottom: 20px;
  margin-left: 20px;
}

.detail-info-block p {
  margin: 0;
  font-weight: 600;
}

.button-with-model {
  display: inline-block;
  margin-left: 20px;
}

.button-with-model button {
  font-size: 13px;
  font-weight: 400;
  border-radius: 3px;
  border: 2px solid #333;
}

.align-right {
  text-align: right;
}

.select-all {
  font-size: 14px;
}

.action-table {
  font-size: 16px;
}

.action-table table {
  width: 100%;
}

.action-table table tr th:first-child,
.action-table table tr td:first-child {
  width: 30%;
}

.action-table table tr th {
  border-bottom: 1px solid #333;
}

.action-table table tr td input {
  margin: 6px 20px 0 0px;
}

.action-table table tr td button {
  margin: 0px 20px 0 0px;
  color: #fff;
  border: 2px solid #ccc;
  padding: 0px;
  height: 24px;
  width: 24px;
  font-size: 13px;
  background-color: rgb(59, 59, 59);
  border-radius: 15px;
}

.action-table table tr td button:hover {
  background-color: #222;
  color: #fff;
  border: 2px solid #ccc;
}

.action-table table tr td select {
  height: 30px;
  margin: -2px 0;
  border: 1px solid #ccc;
  width: 60%;
}

/* Popup Styling------ */

div.modal-width {
  width: 950px !important;
  max-width: 950px !important;
  font-size: 13px;
}

div.modal-header {
  font-size: 15px;
  font-weight: 500;
  background-color: #222;
  color: #fff;
  padding: 10px 20px;
}
div.errorModel {
  font-size: 15px;
  font-weight: 500;
  background-color: #000000;
  color: #fff;
  padding: 10px 20px;
}
.modal-header .btn-close {
  background-color: #fff;
  width: 8px;
  background-size: 12px;
  height: 8px;
  opacity: 1;
}

div.modal-title {
  margin: 10px 0 0 0;
  font-weight: 500;
}

div.modal-title .col-2 {
  text-align: center;
}

div.modal-body {
  text-align: left;
}
/* 
div.shift-list {
  width: 320px;
  display: inline-block;
}

div.shift-list-action { 
  width: 60px;
  display: inline-block;
  padding: 10px 0;
  margin: 40px 0 0 0;

}

.shift-list-block {
  padding: 0px;
}

.shift-list div {
  border: 1px solid #ccc;
  margin: 10px;
}

.shift-list div ul {
  list-style: none;
}

.shift-list div ul li:nth-child(odd) button {
  background-color: #eee;
}

.shift-list div ul li button {
  border: none;
}

.shift-list-action button {
  font-size: 11px;
  width: 100%;
  padding: 7px 0;
  margin: 10px 0;
  border-radius: 3px;
  background-color: #159784;
  color: #fff;
  font-weight: 500;
}

.shift-list-action button:hover{
  background-color: #166c5f;
  color: #fff;
}

button.modal-button{
  font-size: 13px;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid #333;
  color: #333;
}

button.modal-button:hover{
  border: 1px solid #333;
  color: rgb(244, 232, 232);
  background-color: rgb(48, 42, 42);
} */

/* Project list - left side panel */

/* Main menu styles */
.project-list {
  background-color: #333;
  color: #fff;
  font-size: 14px;
  /* height: 100vh; */
  /* width: 360px; */
}

.project-list-l1 {
  border-bottom: 1px solid #222;
}

.project-list-l1 table {
  width: 100%;
}

.project-list-l1 td {
  padding: 10px;
  text-align: left;
  cursor: pointer;
}

.project-list-l1 td span {
  padding: 0 !important;
}

.project-list-l1-row td:first-child {
  width: 20px;
}

.project-list-l1-row td:last-child {
  float: right;
}
.project-list-l1-row.active {
  background-color: #159784;
}
.project-list-l2 {
  background-color: #e7e7e7;
  color: #222;
  border-bottom: 1px solid #d5d5d5;
  cursor: pointer;
}

.project-list-l2-row.active {
  background-color: #159784;
}
.project-list-l2 td:first-child {
  padding-left: 20px;
}

.project-list-l2 td:last-child {
  float: right;
}

.list-icon {
  margin-right: 10px;
  border-color: transparent;
  background-color: #e7e7e7;
  color: #222;
}

.list-icon.active {
  background-color: #159784;
  color: #fff;
}

.project-list-icon {
  margin-right: 10px;
  border-color: transparent;
  background-color: #333;
  color: #fff;
}

.project-list-icon.active {
  background-color: #159784;
  color: #fff;
}

.project-list-l3 {
  background-color: #fff;
  color: #222;
  padding-left: 30px;
  cursor: pointer;
}

.project-list-l3-row.active {
  background-color: #159784;
}

.project-list-l3 table {
  border-bottom: 1px solid #eee;
}

/* OLD Styles--------- */

.form-block {
  font-size: 16px;
}
/* .form-check{
  padding-top: .5em;
  padding-left: 8.5em !important;
} */
.form-check-input:checked {
  background-color: #92959b !important;
  border-color: #898e95 !important;
}

.form-check-label-input {
  font-weight: 600;
  color: rgb(79, 70, 70);
}

.notify {
  text-align: center;
  margin: 40px auto;
  background: linear-gradient(90deg, #403fe3, #497ab5) !important;
  width: 142px;
  color: #fff;
  border: 2px solid #497ab5;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
}

.loading-spinner {
  width: 15px;
  height: 15px;
  border: 3px solid #e8e8e8;
  border-top: 3px solid#f3f3f3;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  /* height: 50px; */
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.list-group-item[aria-expanded="true"] {
  background-color: black !important;
  border-color: #aed248;
}

/* day card and item card*/

.col-md-1 {
  /* background-color: gold; */
}

/* @media (min-width: 768px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 100%;
    *width: 100%;
  }
} */

/* @media (min-width: 992px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    margin: 0px !important;
    padding-left: 0px;
  }
} */
/* @media (min-width: 992px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
    *width: 14.285714285714285714285714285714%;
    margin: 0px !important;
    padding-left: 0px;
  }
} */
/* @media (min-width: 1200px) {
 
} */

/*---------------------------------------------------
*/

.appr_project_name {
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0 0px 20px;
  border: 1px solid #ebebeb;
  padding: 3px 10px;
  border-radius: 4px;
  background-color: #fafafa;
}

.af_table_data-row {
  background-color: #363636 !important;
  border-right: none !important;
  border-bottom: 1px solid #4a4a4a !important;
  height: 32px !important;
  padding: 0px !important;
}

.af_region,
.page_header {
  background-color: #fff;
}
/* 
.default_btn{
  border: 2px solid #363636 !important;
  background-image: none !important;
  background-color: #fff !important;
  padding: 3px 10px;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 4px;
  color: #333 !important;
  cursor: pointer;
  transition: background-color ease 0.3s;
}
.dark_btn,.dark_btn_tot{
  border: 2px solid #363636 !important;
  background-image: none !important;
  background-color: #3a3a3a !important;
  padding: 5px 20px;
  border-radius: 5px;
  margin-right: 17px;
  margin-top: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color ease 0.3s;
}
.dark_btn_tot{
  cursor: text !important;
  
} */
/* .red_btn{
  border: 2px solid #363636 !important;
  background-image: none !important;
  background-color: #E24131 !important;
  padding: 5px 20px;
  border-radius: 3px;
  margin-right: 20px;
  margin-top: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color ease 0.3s;
}

.green_btn{
  cursor: pointer !important;
  border: 2px solid #363636 !important;
  background-image: none !important;
  background-color: #16a086 !important;
  padding: 5px 20px;
  border-radius:3px;
  margin-right: 17px;
  margin-top: 4px;
  color: #fff;

  transition: background-color ease 0.3s;
} */
.freeze_green_btn {
  cursor: wait !important;
  border: 2px solid #363636 !important;
  background-image: none !important;
  background-color: #5416a0 !important;
  padding: 3px 10px;
  border-radius: 5px;
  margin-right: 17px;
  margin-top: 4px;
  color: #fff;

  transition: background-color ease 0.3s;
}

.seven-cols {
  margin-left: -20px;
  margin-right: 0;
}

.header-hrs {
}

.day-number {
  margin-bottom: 2px;
}

.day-name {
  font-size: 12px;
  color: #969696;
  font-weight: 400;
}

.hr-lbl {
  font-size: 12px;
  color: #fff;
  background-color: #332;
  display: block;
  text-align: center;
  padding: 2px;
  font-weight: 400;
  border-radius: 3px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
}

.af_listItem,
.af_listView {
  border: none !important;
}
.af_listItem {
  margin: 0px 0px 5px 3px;
  border-radius: 5px;
  padding: 8px;
  border: 1px solid #ebebeb !important;
  min-height: 10px;
  background-color: #ebebeb;
  background: -webkit-linear-gradient(#fbfbfb, #ebebeb);
  background: -o-linear-gradient(#fbfbfb, #ebebeb);
  background: -moz-linear-gradient(#fbfbfb, #ebebeb);
  background: linear-gradient(#fbfbfb, #ebebeb);
  max-width: 150px !important;
  display: inline-block !important;
}

.appr_col {
  width: 14.2857% !important;
  /* border-left: 1px solid #ebebeb; */
}
.appr_card_title {
  border-bottom: 1px solid #cacaca;
  width: 100%;
  display: block;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.appr_invoice_cmnt {
  word-wrap: break-word;
  height: auto;
  font-size: 12px;
  font-weight: 500;
}

.appr_card_time_ProjectItem {
  font-size: 26px;
  font-weight: 800;
  padding: 15px 8px;
  margin: 0px 0px 5px 3px;
  margin-top: 10px;
  display: block;
}
.appr_card_time {
  font-size: 36px;
  font-weight: 800;
  margin-top: 10px;
  display: block;
}
.appr_card_lbl {
  font-size: 13px;
  font-weight: 400;
  display: block;
  margin-top: -5px;
  color: #666;
}

.card_checkbox {
  right: 10px;
  position: absolute;
  bottom: 5px;
}

.btn.rounded-circle {
  width: 32px;
  height: 32px;
  /* margin: 15px; */
}

.btn.rounded-circle:hover {
  background-color: #ccc;
}

.btn.rounded-circle img {
  width: 18px;
  margin-bottom: 1px;
}

.rounded-pill {
  background-color: #333;
  padding: 2px 10px 3px 10px;
  border: none;
}

.rounded-pill a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

button.rounded,
label.rounded {
  background-color: #333 !important;
  padding: 2px 10px 3px 10px;
  border: none;
  font-size: 14px;
}

.algn-right {
  text-align: right;
}

.red_btn,
.green_btn {
  font-size: 14px;
}
.list-group-flush.team li {
  background-color: #e8e8e8;
  border: none;
  color: #201717;
  border-bottom: 1px solid #444;
  /* transition: background-color ease 0.3s !important; */
}
.list-group-flush.team li:hover {
  background-color: #efecec;
}
.list-group-flush.task li {
  background-color: white;
  border: none;
  color: #120c0c;
  border-bottom: 1px solid #444;
}
.list-group-flush.task li:hover {
  background-color: whitesmoke;
}
.list-group-flush li {
  background-color: #333;
  border: none;
  color: #fff;
  border-bottom: 1px solid #444;
  /* padding: 0px; */
}
.list-group-flush li:hover {
  background-color: #0e0b0b;
}

.list-group-flush li button {
  border: none;
  color: #ccc;
  font-size: 14px !important;
}

.list-group-flush .list-group-item.active {
  background-color: #16a086;
  border-color: #16a086;
}
.list-group-flush .list-group-item.active:hover {
  background-color: #098971;
  border-color: #16a086;
}
.list-group-flush .firstList {
  /* background-color: #ccc; */
  padding: 0px;
}
.list-group-flush li .col-10 {
  padding-left: 10px;
}
.list-group-item div {
  display: inline !important;
}

/* user Name

teamMember Card 
22 MAY TO 28 MAY display in line -> mainmenue -> 2328

Model
*/

/*
  New Time Approver HTML Structure's CSS
*/


* {
  font-family: "DM Serif Display", serif;
  font-family: "Open Sans", sans-serif;
}

body {
  padding: 0;
  margin: 0;
}

.ch-container {
  width: 100%;
}

/* Page main header and loggin info */
.ch-header {
  height: 48px;
}

.app-logo {
  width: 200px;
  background-color: #16a085;
  height: 48px;
  line-height: 48px;
  padding-left: 20px;
  color: #fff;
}

/* Main navigation and status info */
.ch-main-nav {
  height: 40px;
  background-color: #ececec;
  display: flex;
}

.week-nav {
  margin-left: 20px;
  width: 50%;
  display: flex;
  font-size: 18px;
}

.prev-week,
.next-week {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  transition: all ease 0.3s;
  border-radius: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.prev-week {
  background: url(arrow-left.png);
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: center;
}

.next-week {
  background: url(arrow-right.png);
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: center;
}

.prev-week:hover,
.next-week:hover {
  background-color: #ccc;
  transition: all ease 0.3s;
}

.week-nav .date {
  margin: 0 20px;
  height: 40px;
  line-height: 40px;
}

.week-no {
  font-size: 16px;
  color: #353535;
  height: 40px;
  line-height: 44px;
  margin-left: 40px;
  text-transform: none;
  font-weight: 400;
  white-space: nowrap;
}

.nav-actions {
  line-height: 40px;
  margin-left: auto;
  margin-right: 20px;
}

/* Acction button section */
.ch-actions {
  /* height: 40px; */
  background-color: #fff;
  display: flex;
  margin-top: 15px;
}

.ch-project-name {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 0 20px;
  border: 1px solid #ebebeb;
  padding: 5px 5px 5px 5px;
  border-radius: 4px;
  background-color: #fafafa;
  max-width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ch-main-actions {
  margin-left: auto;
  padding-right: 20px;
}

/* Dynamic data contaciner block */
.ch-dynamic-container {
  display: flex;
  height: 100vh;
}

/* Project task and user list/Tree view */
.ch-tree-list {
  height: 100vh;
  width: 25%;
  min-width: 25%;
  max-width: 25%;
  padding: 20px 0;
}

.ch-tree-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ch-tree-list > ul > span > li {
  padding: 0px;
  border-bottom: 1px solid #222;
  font-size: 12px;
  background-color: #333;
  color: #fff;
  transition: all ease 0.3s;
  cursor: pointer;
}

.ch-tree-list > ul > span > li:hover {
  background-color: #222;
  transition: all ease 0.3s;
  cursor: pointer;
}

.ch-list-content {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
.ch-list-content.active {
  background: linear-gradient(90deg, #403fe3, #497ab5) !important;
  color: #fff;
}
.ch-list-content.active:hover {
  background-color: #4972bd;
  color: #fff;
  transition: all ease 0.3s;
}
.ch-list-content > div:last-child {
  margin-left: auto;
}

.ch-tree-list > ul > span > ul > span > li {
  padding: 0px;
  background-color: rgb(214, 214, 214);
  color: #222;
  transition: all ease 0.3s;
  border-bottom: 1px solid #222;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.ch-tree-list > ul > span > ul > span > li:hover {
  background-color: rgb(189, 189, 189);
  transition: all ease 0.3s;
  cursor: pointer;
}

.ch-tree-list > ul > span > ul > span > ul > span > li {
  padding: 0px;
  background-color:rgb(245, 245, 245)!important;
  color: #222;
  transition: all ease 0.3s;
  border-bottom: 1px solid #222;
  font-size: 12px;
  cursor: pointer;
}

.ch-tree-list > ul > span > ul > span > ul > span > li:hover {
  background-color: rgb(231, 231, 231);
  transition: all all ease 0.3s;
  cursor: pointer;
}

.ch-tree-list > ul > li > ul > li > ul > li:last-child {
  border-bottom: none;
}
.arrow {
  font-size: 12px;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  color: #92959b;
}
.down {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  color: #92959b;
}
.list-icon {
  flex: 0 0 24px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  background-color: transparent;
  color: white;
  font-size: 14px;
}

.list-text {
  width: auto;
  max-width: 75%;
  word-wrap: break-word;
}

.list-value {
  flex: 0 0 24px;
  text-align: right;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

.ch-tree-list .list-text {
  padding: 10px;
}

/* Time card section and week view */
.ch-calender {
  height: 100vh;
  width: 75%;
  min-width: 75%;
  max-width: 75%;
  display: flex;
  padding: 20px 10px;
}

.ch-week-column {
  /* flex: 1 1 0px; */
  width: 14.2857%;
  max-width: 14.2857%;
  display: inline-block;
  word-wrap: break-word;
}

.ch-week-header {
  border-right: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  margin-bottom: 10px;
  padding: 10px 15px;
}

.ch-week-column:last-child .ch-week-header {
  border-right: none;
}

.ch-day {
  display: flex;
}

.ch-day-no {
  font-size: 24px;
  font-weight: 500;
}

.ch-day-no span {
  font-size: 10.5px;
  color: #333333;
  display: block;
  font-weight: 300px;
}

.ch-day-select {
  margin-left: auto;
  margin-top: 25px;
}

.ch-day-select input {
  margin: 0;
}

.ch-hours {
  margin-top: 10px;
  font-size: 11px;
  text-align: center;
}

.ch-hours span {
  background-color: #f6f6f6;
  border-radius: 20px;
  display: block;
  color: #333;
  padding: 3px 5px;
  font-weight: 700;
  border: 1px solid #333;
}

.ch-week-column-item {
  border: 1px solid #ececec;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  min-height: 160px;
  margin: 5px;
  background: #ececec;
  background: linear-gradient(0deg, #f5f5f5, #ffffff);
  width: 95%;
  max-width: 95%;
  display: inline-block;
}

/* Single card */
.card-header {
  font-size: 13px;
  color: #333;
  margin-bottom: 7px;
  padding-bottom: 7px;
  border-bottom: 1px solid #eee !important;
  font-weight: 600;
  /* max-width: 100%;
  word-wrap: break-word; */

}

.card-hours {
  display: block;
  text-align: left;
  margin-top: 20px;
}

.hr-value {
  font-size: 32px;
  color: #222;
  font-weight: 500;
  text-align: left;
  margin-bottom: 0 !important;
  line-height: 42px;
  max-width: 100%;
}


.hr-lbl {
  font-size: 15px;
  color: rgb(185, 185, 185);
  margin: 0 !important;
  background-color: transparent;
  text-align: left;
} 

.time-progress {
  margin: 10px 0;
  border-bottom: 1px solid #333333 !important;
}

.time-info {
  font-size: 9px;
  color: #333;
  font-weight: 500;
  display: flex;
}

.time-info-desc {
  width: 50%;
  font-weight: 600;
}

.time-info-hrs {
  margin: 0 5px;
  font-size: 20px;
}
.time-info-lbl {
  font-size: 11px;
  color: rgb(186, 186, 186);
  margin-top: 9px;
}
.card-select {
  display: flex;
  flex-direction: row-reverse;
}

.card-select input {
  margin: 0;
}

.invoice-comment-header {
  font-size: 12px;
  color: #333;
  margin-bottom: 7px;
  padding-bottom: 7px;
}

.invoice-comment-label {

  font-weight: 600;
  color: #333;
  border-bottom: 1px solid #333333 !important;
}
.invoice-comment-value{
  max-width: 130px;
  word-wrap: break-word;
}

.confirm-button,
.total-time-of-pending {
  border-bottom: 1px solid #333 !important;
  background-color: #333 !important;
  border-radius: 5px;
  color: #fff !important;
  cursor: pointer;
  transition: all ease 0.3s;
  font-size: 12px;
}
.total-time-of-pending {
  margin-left: 5px;
  margin-right: 5px;
  cursor: default !important;
  transition: background-color ease 0.3s;
}
.confirm-button:hover {
  background-color: #222 !important;
  transition: all ease 0.1s !important;
  cursor: pointer;
}
.total-time-of-pending:hover {
  background-color: #222 !important;
  transition: all ease 0.3s !important;
  cursor: pointer;
}
.red_btn {
  background-image: none !important;
  background-color:  #dc3545 !important;
  color: #fff !important;
  cursor: pointer;
  transition: background-color ease 0.3s;
  margin-right: 10px;
}

.green_btn {
  cursor: pointer !important;
  background-image: none !important;
  background-color: #4972bd !important;
  color: #fff !important;
  transition: background-color ease 0.3s;
  margin-right: 5px;
}

.green_btn:hover {
  background-color: #395fa4 !important;
  transition: all ease 0.3s !important;
}
.red_btn:hover {
  background-color: #e24858 !important;
  transition: all ease 0.3s !important;
}

.dark_btn {
  padding: 0px;
  font-size: 12px;
  background-color: #222 !important;
  color: #fff !important;
  border: 2px solid #363636 !important;
  transition: background-color ease 0.3s;
  margin-right: 5px;
}
.dark_btn:hover {
  background-color: #fff !important;
  color: #333 !important;
  transition: all ease 0.3s !important;
}

.viewDashboard {
  padding: 5px;
  font-size: 12px;
  background-color: #222 !important;
  color: #fff !important;
  border: 2px solid #363636 !important;
  transition: background-color ease 0.3s;
  margin-left: 20px !important;
  margin: 5px;
  border-radius: 15px;
  padding-left: 12px;
  padding-right: 12px;
  text-decoration: none !important;
}
.viewDashboard > a {
  background-color: #222 !important;
  color: #fff !important;
  transition: all ease 0.3s !important;
}
.viewDashboard:hover {
  background-color: #333 !important;
  color: #ece6e6 !important;
  transition: all ease 0.3s !important;
}

.viewDashboard > a:hover {
  background-color: #333 !important;
  color: #fff !important;
  transition: all ease 0.3s !important;
}
.model-footer-button {
  margin-top: 90%;
  display: inline-flex;
}
.confirm-approved-table {
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.t-head {
  border: none;
  background-color: #666 !important;
  height: 24px;
  vertical-align: middle;
  color: #fff;
  text-shadow: none;
  font-size: 12px;
  margin-right: 15px !important;
  padding-top: 3px;
}
.t-table-row{
  display: flex;
}
.t-row{
  border-bottom: 1px solid #e7e0e0;
}
.team-member-name{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.confirm-approved-table .col-2{
  width: 17.5%;
  padding: 0px;
}
 .t-time-tot{
  padding: 0px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


/*Banner CSS*/

.ch-notif-banner .modal.show .modal-dialog{
  width: 460px !important;
}

.ch-notif-banner .modal-content{
  background-color: transparent;
}

.ch-notif-banner .bannermodal-body{
  border-radius: 12px;
  overflow: hidden;
}

.ch-notif-banner img{
  width: 100%;
}

.ch-notif-banner .btn-close{
  right: 0;
  border: 1px solid #fff;
  margin-top: -20px;
  background-color: #fff;
  padding: 7px;
  border-radius: 20px;
  margin-right: -20px;
  position:  absolute;
}
.banner-content{
  padding: 0px;
  max-width: fit-content;
}

.banner.modal-title{
  margin: 0px;
}
.center_nf {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #fff;
  padding-bottom: 20%;
}


.center_nf .f04 {
  color: rgb(8, 27, 75);
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -2px;
  line-height: 1.2;
  margin: 0px;
  text-align: center;
  margin-bottom: 2%;
}
.center_nf .pnf {
  margin-top: 2%;
  font-size: 15px;
  color: #333;
  justify-content: center;
  align-items: center;
  display: flex;

}
.center_nf .pnf a{
  width: 100px;
  height: 40px;
  /* margin-right: 20px; */
  font-size: 18px;
  color: rgb(8, 27, 75);
  font-weight: normal;
  /* margin-bottom: 5px; */
  white-space: nowrap;
  font-weight: 600;
  text-decoration: none !important;
  border: 2px solid rgb(8, 27, 75);
  border-radius: 15px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 2px;
  text-align: center;
  
}
.center_nf .pnf a:hover{
  background-color: rgb(8, 27, 75);
  color: #f8f8f8;
}
.inline-row {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}